.term_conditions_data{
    background-color: #fff;
    padding: 30px;
    box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
    border-radius: 6px;
    h4{
      margin-bottom: 20px;
   }
     h5{
        margin-bottom: 12px;
     }
     p{
      font-size: 15px;
     }
     ol{
      li{
         list-style: circle;
      }
     }
}




