@mixin payment_status    {
    color: #fff;
    padding: 0px 5px;
    border-radius: 3px;
    font-weight: 600;
}

.history_section{
    padding: 25px 25px;

    .transaction_history{
        box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
        background: #fff;
        border-radius: 5px;
        .title {
            border-bottom: 1px solid #e9ebec;
            padding: 1rem 1rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            h6 {
                font-size: 17px;
                margin-bottom: 0rem;
            }
        }
        .history_table {
            padding: 1rem 1rem;
            .MuiTableContainer-root{
                border: 1px solid #80808033;
                .MuiTable-root {
                    thead{
                        background: #f1f1f1;
                        tr{
                            .MuiTableCell-head {
                                font-weight: 600 !important;
                            }
                        }
                    }
                    p {
                        margin-bottom: 0rem !important;
                    }

                    .MuiTableCell-root {
                        border-right: 1px solid #80808033;
                        padding: 10px;
                            &:nth-last-child(-n+1){
                                border-right: 0px; 
                            }
                        .yes_status {
                            background: rgb(10, 179, 156);
                            @include payment_status ;
                        }

                        .no_status {
                            background: rgb(240, 101, 72);
                            @include payment_status ;
                        }

                    }
                }
            }
}
 .filter_input {
    display: flex;
    align-items: center;

    label {
        width: 100px;
        font-weight: 500;
        color: #525f7f;
    }
    .form-select:focus {
        border-color: #ced4da;
    }

}
    }
}
.ongoing{
    padding-top: 0px;
}