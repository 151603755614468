.notification_section {
    padding: 25px 25px;

    .notification_content {
        box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
        background: #fff;
        border-radius: 16px;

        .notification_title {
            border-bottom: 1px solid #e9ebec;
            padding: 1rem 1rem;

            h3 {
                margin-bottom: 0rem;
            }
        }

        .table_content {

            .notification_table {
                padding: 1rem 0rem;

                table {
                    tfoot {
                        tr {
                            td {
                                border-top: 1px solid rgba(224, 224, 224, 1);
                                border-bottom: 0px;

                                p {
                                    margin-bottom: 0rem;
                                }

                            }
                        }
                    }
                }

                .notification_text {
                    border: 1px solid lightgray;
                    border-radius: 16px;
                    padding: 15px;

                    p {
                        margin-bottom: 0rem;
                        color: #6b6b6b;
                    }
                }
            }
        }
    }
}