@mixin subTitle_class {
    font-weight: 700;
    font-size: 20px;
}

@mixin box_class {
    padding: 2rem;
    border: 2px solid gray;
    border-radius: 4px;
    margin-top: 15px;
}

@mixin btn_class {
    background-color: #abb7ff;
    width: 117px;
    color: #000;
    background-image: none;
    font-size: 23px;
    padding: 0.13em;
}

@mixin input_class {
    width: 70%;
    border-bottom: 2px solid gray;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
    margin-bottom: 20px;
}

.mainLiquidity_section {
    padding: 25px 25px;

    .liquidity_content {
        box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
        background: #fff;
        border-radius: 5px;
        margin-bottom: 10px;
        padding: 2rem 2rem;

        .liquidity_title {
            h3 {
                font-weight: 700;
            }
        }

        .liquidity_btns {
            .btns {
                .primary_btn {
                    width: 300px;
                    font-size: 23px;
                    padding: 0.13em;
                }
            }

            .active_pool_btn {
                .primary_btn {
                    background-color: #abb7ff;
                    width: 300px;
                    color: #000;
                    background-image: none;
                    font-size: 23px;
                    padding: 0.13em;
                }
            }
        }

        .add_liquidity {
            .sub_title {
                @include subTitle_class;
            }

            .add_liquidity_box {
                @include box_class;

                p {
                    font-size: 18px;
                    font-weight: 500;
                }

                .select_option {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .primary_btn {
                        @include btn_class;
                    }

                    h5 {
                        font-size: 23px;
                    }
                }

                .select_inputs {
                    display: flex;
                    align-items: flex-end;
                    margin-top: 40px;

                    input {
                        @include input_class;

                        &:focus {
                            box-shadow: none;

                        }
                    }
                }
            }
        }

        .trad_section {
            .sub_title {
                @include subTitle_class;
            }

            .trad_box {
                @include box_class;

                p {
                    font-size: 18px;
                    font-weight: 500;
                }

                .select_option {
                    display: flex;
                    gap: 50px;

                    .primary_btn {
                        @include btn_class;
                    }

                }

                .amount_trad {
                    .amount_input {
                        display: flex;
                        align-items: flex-end;
                        gap: 30px;

                        input {
                            @include input_class;
                            width: 35%;
                        }
                    }

                }
            }
        }
    }
}

.liquidity_pool {
    .banner {
        background-color: #fff;
        padding: 1em;
        border-radius: 10px;
        border: 1px solid #efefef;
    }

    .pool_bar_head {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .search_bar {
        display: flex;
        align-items: center;

        label {
            font-size: 15px;
            font-weight: 600;
            color: #ca5c68;
        }

        input {
            border: 1px solid #ababab;
            border-radius: 22px;
            padding: 7px 10px;
            margin-left: 10px;
            width: 250px;

            &:focus {
                border: 1px solid #ababab;
                padding: 7px 10px;
                box-shadow: none;
            }
        }
    }

    .nav-tabs {
        border-bottom: none;
        border: 1px solid #ffd7d7;
        width: fit-content;
        border-radius: 22px;
        background: #ffe2e28a;
    }

    .nav-item {
        background: #ffe2e28a;
        border-radius: 22px;
    }

    .nav-tabs .nav-link.active {
        padding: 0.3em 2em;
        border-radius: 20px;
        background: #ee5d6db8;
        color: #fff;
        font-weight: 600;
        border: 1px solid #ffd7d7;
    }

    .nav-tabs .nav-link {
        color: #ca5c68;
        font-weight: 600;
        padding: 0.3em 1em;

        &:hover {
            border-radius: 20px;
        }
    }

    .pool_box {
        background-color: #fff;
        border-radius: 15px;
        margin: 1em 0em;
        position: relative;


        .top_section {
            // background-color: ;
            padding: 2em;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #e7e7e78f;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            border: 1px solid #e0e0e0;


            .content {
                h4 {
                    margin: 0%;
                    color: #ee5d6d;
                }

                p {
                    margin: 0%;
                    font-size: 14px;
                    color: #ca5c68;
                    font-weight: 500;
                }
            }

            .pool_logo {
                img {
                    width: 70px;
                    height: 70px;
                }
            }
        }

        hr {
            margin: 0rem;
        }

        .mid_section {
            padding: 1em 2em;

            .list_bar {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                font-size: 15px;
                font-weight: 500;
                color: #ab4141;
            }

            .start_earning {
                font-size: 13px;
                font-weight: 600;
                color: #ca5c68;
            }

            .wallet_connect {
                display: flex;
                justify-content: center;
                margin-top: 10px;

                button {
                    width: 100%;
                }
            }
        }

        .bottom_section {
            padding: 1em 2em;

            .accordion {
                --bs-accordion-border-color: transparent !important;

                .accordion-header {
                    button {
                        padding: 0rem;
                        width: 22%;
                        display: block;
                        margin-left: auto;
                        margin-bottom: 1em;
                        border: none;
                        background: none;
                        font-weight: 600;
                        color: #ee5d6d;

                        svg {
                            font-size: 18px;
                            margin-top: -1px;
                        }
                    }
                }

                .accordion-body {
                    padding: 0rem;
                }
            }

            .accordion-button:focus {
                box-shadow: none !important;
            }

            td {
                border: none;
                padding: 0;
                color: #993843;
                line-height: 1.5;
                font-size: 14px;

                .swap_btn {
                    margin-top: 20px;
                }

                .add_liquidity_btn {
                    margin-top: 20px;
                    text-align: right;
                    width: 100%;
                }
            }

            .property_value {
                text-align: end;
            }
        }
    }
}

.add_liquidity_form {
    .liquidity_popup_wrap {
        .liquidity_popup {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 5px;

            &:first-child {
                margin-bottom: 30px;
            }

            .liquidity_input {
                .form-control {
                    &:focus {
                        box-shadow: none;
                    }
                }

                width: 100%;
            }

            .liquidity_btn_wrap {
                .liquidity_btn {
                    margin-top: 25px;
                    border-radius: 20px;
                    background: rgba(238, 93, 109, 0.7215686275);
                    color: #fff;
                    font-weight: 600;
                    border: 1px solid #ffd7d7;
                }
            }
        }

        .liquidity_add_btn_wrap {
            margin-top: 25px;

            .liquidity_add_btn {
                border-radius: 20px;
                background: rgba(238, 93, 109, 0.7215686275);
                color: #fff;
                font-weight: 600;
                width: 100%;
                border: 1px solid #ffd7d7;
            }
        }

        .liquidity_element {

            .form-control,
            .form-select {
                &:focus {
                    box-shadow: none;
                }
            }

            &:first-child {
                margin-bottom: 20px;
            }
        }

        .liquidity_btn_wrap {

            .liquidity_btn {
                margin-top: 25px;
                border-radius: 20px;
                background: rgba(238, 93, 109, 0.7215686275);
                color: #fff;
                font-weight: 600;
                width: 100%;
                border: 1px solid #ffd7d7;
            }
        }
    }
}


@media (min-width:1200px) and (max-width:1399px){
    .liquidity_pool .pool_box .bottom_section .accordion .accordion-header button {
        width: 25%;
    }
}
@media (min-width:992px) and (max-width:1199px){
    .liquidity_pool .pool_box .bottom_section .accordion .accordion-header button {
        width: 32%;
    }
}
@media (min-width:768px) and (max-width:991px){
    .liquidity_pool .pool_box .bottom_section .accordion .accordion-header button {
        width: 27%;
    }
}
@media (min-width:512px) and (max-width:767px){
    .liquidity_pool .pool_box .bottom_section .accordion .accordion-header button {
        width: 17%;
    }
}
@media (min-width:451px) and (max-width:511px){
    .liquidity_pool .pool_box .bottom_section .accordion .accordion-header button {
        width: 20%;
    }
}
@media (min-width:320px) and (max-width:410px){
    .liquidity_pool .pool_box .bottom_section .accordion .accordion-header button {
        width: 32%;
    }
}