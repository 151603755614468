.admin_dashboard_section {
    padding: 25px 25px;

    .user_count_box {
        box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
        background: #fff;
        border-radius: 16px;
        padding: 35px;
        margin: 15px 0px;


        .head {
            display: flex;
            // align-items: center;
            gap: 20px;
            .icon{
                width: 42px;
                height: 42px;
                border-radius: 50%;
                background: #dbdbdb;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 23px;
                 svg{
                    color:#717171;
                 }
            }

            p {
                color: #878a99;
                margin-bottom: 0rem;
            }
        }

      .total_count{
                color: #495057;
                font-size: 1.525rem;
            }
   

    }
}