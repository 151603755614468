.contact_section {
    padding: 60px 0px;

    .contact_content {

        .contact_head {
            h2 {
                text-align: center;
                padding:0px 0px 20px 0px;
                color: #fff;
            }
        }

        .contact_detail {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .email_box {
                display: flex;
                align-items: center;
                gap: 20px;

                .email_icon {
                    width: 50px;
                    height: 50px;
                    background: #8080804f;
                    color: #fff;
                    font-size: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    margin-top: -14px;
                }

                .email_text {
                    h6 {
                        color: #fff;
                        font-size: 21px;
                        font-weight: 400;
                        margin-bottom: 0rem;
                    }

                    p {
                        a{
                            font-size: 17px;
                        color: #FF465A;
                        }
                    }
                }
            }

            .follow_us_box {
                p {
                    font-size: 19px;
                    color: gray;
                    padding-left: 15px;
                    padding-top: 10px;
                }

                ul {
                    display: flex;
                    margin-left: -20px;
                    margin-top: 38px;
                    gap: 10px;

                    li {
                        width: 40px;
                        height: 40px;
                        background: #8080804f;
                        font-size: 21px;
                        display: flex;
                        justify-content: center;
                        border-radius: 50%;
                        margin-top: -14px;

                        a {
                            color: #d0d0d0;

                            svg {
                                margin-top: 4px;
                            }
                        }
                    }
                }
                .mail_box {
                    padding-top: 15px;
    
                    h4 {
                        font-size: 20px;
                        font-weight: 500;
                        color: #fff;
                    }
    
                    .email_input {
                        display: flex;
                        align-items: center;
                        background-color: #fff;
                        border-radius: 5px;
                        margin-top: 17px;
                        padding: 8px;
                        margin-right: 30px;
    
                        input {
                            background-color: transparent;
                            padding: 0px;
                            border: none;
    
                            &:focus {
                                box-shadow: none;
                                border: none;
                            }
                        }
    
                        .button_icon {
                            border: none;
                            background-color: transparent;
                            padding: 0px;
    
                            svg {
                                color: #ff4c60;
                                font-size: 30px;
                            }
                        }
                    }
                }
            }
        }



        .contact_form {
            .form-floating {
                margin: 15px 0px;

                .form-control {
                    background: #45444440;
                    border: 1px solid #3a3a3a;
                    color: #ffffffdb;

                    &:focus {
                        box-shadow: none;
                        border: 1px solid #3a3a3a;
                    }
                }

                label {
                    color: gray;
                }
            }

            .submit_btn {
                button {
                    border: none;
                    background: #ff495d;
                    color: #fff;
                    padding: 17px 0px;
                    border-radius: 5px;
                }
            }
        }
    }
}
.contact_us_modal {
    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
            font-size: 60px;
            color: green;
        }

        p {
            font-size: 18px;
            padding: 20px 0px;
        }
    }
}