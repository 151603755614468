.main_staking_section {
    padding: 25px 25px;

    .staking_title {
        h3 {
            font-weight: 700;
        }
    }

    .staking_count_box {
        box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
        background: #fff;
        border-radius: 16px;
        padding: 35px;
        margin: 15px 0px;

        .head {
            display: flex;
            gap: 7px;
        }
    }
}

.staking_token_section {
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    background: #fff;
    border-radius: 16px;
    padding: 30px;
    margin: 15px 0px;

    .staking_tabs {
        display: flex;
        justify-content: space-between;
        border-bottom: none;
    }

    .nav-tabs .nav-link.active {
        color: #101111;
        font-size: 19px;
        font-weight: 600;
        border-color: transparent;
        border: none;
    }

    .nav-tabs .nav-link {
        border: none;
        color: gray;
        font-size: 19px;
        font-weight: 600;
        // padding-left: 0px;
        padding: 0rem 1rem !important;

        &:hover {
            border: none;
            padding: 0.5rem 1rem;
        }
    }


    .staking_content {
        margin-top: 20px;

        // .staking_btn {
        //     display: flex;
        //     flex-direction: row-reverse;
        //     gap: 2em;
        // }

        .text {
            font-size: 16px;
            font-weight: 500;
        }

        .generated_token {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .input_feild {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            appearance: none;
            border-radius: 0.375rem;

            .token_name {
                font-size: 17px;
                font-weight: 600;

            }

            input {
                border: none;

                &:focus {
                    box-shadow: none;
                    border: none;
                }
            }
        }

        .staking_points_div {
            margin-top: 50px;
            height: 200px;


            .staking_points {
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 2;

                .title {
                    color: gray;
                }

                .points {
                    font-weight: 500;
                }
            }
        }

        .staking_btn {
            button {
                width: 100%;
                margin-top: 20px;
            }
        }

        .unstake_table{
            .unstake_btn{
                font-size: 15px;
                padding:  0.175rem  0.35rem;
            }
        }
    }
}

.staking_overview_section {
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    background: #fff;
    border-radius: 16px;
    padding: 30px;
    margin: 15px 0px;

    .head {
        display: flex;
        justify-content: space-between;

        h6 {
            color: #101111;
            font-size: 20px;
            font-weight: 600;
        }

        .staking_dropdown {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;

            select {
                border: 1px solid gray;
                border-radius: 5px;
                padding: 2px;
                font-weight: 500;
            }
        }
    }

    .staking_overview_chart {
        padding-top: 20px;

        h2 {
            font-size: 30px;
            padding-bottom: 20px;
            padding-left: 20px;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                background-color: rgb(255, 99, 132);
                width: 14px;
                height: 14px;
                border-radius: 50%;
                top: 12px;
                left: -1px;
            }

            span {
                font-size: 13px;
                color: gray;
            }
        }

        .line_chart_div {
            height: 380px !important;
            width: 100%;

            canvas {
                height: 380px !important;
                // width: 52vw !important;
            }
        }
    }

}

@media screen and (max-width:1496px){
    .staking_token_section .staking_tabs {
        display: flex;
        justify-content: space-evenly;
    }
}

@media screen and (max-width:991px){
    .staking_overview_section .staking_overview_chart .line_chart_div canvas {
        // width: 70vw !important;
    }
}

@media (min-width:1026px) and (max-width:1399px){
    .staking_overview_section .staking_overview_chart .line_chart_div canvas {
        // width: 44vw !important;
    }
}
@media (min-width:1400px) and (max-width:1580px){
    .staking_overview_section .staking_overview_chart .line_chart_div canvas {
        // width: 50vw !important;
    }
}