@mixin profile_box {
    box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
    background: #fff;
    border-radius: 16px;
}

@mixin profile_title {
    font-weight: 500;
    font-size: 20px;
}

.profile_section {
    padding: 35px 25px;

    .profile_img_div {
        @include profile_box;
        padding: 30px 50px 50px;
        margin-top: 10px;

        .profile_title {
            p {
                @include profile_title;
                text-align: center;
                margin-bottom: 20px;
            }
        }

        .Profile_image {
            display: flex;
            justify-content: center;

            input[type="file"] {
                display: none;
            }

            .img_camera {
                position: relative;
                display: flex;
                justify-content: center;

                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 100%;
                    object-fit: fill;
                    background: #f3f3f3;
                }

                .custom-file-upload {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    border: 1px solid #ccc;
                    padding: 2px 7px;
                    border-radius: 50%;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    position: absolute;
                    top: -5px;
                    right: 3%;

                    svg {
                        font-size: 22px;
                    }
                }
                .active{
                    background: #1f76ce;
                    cursor: pointer;
                }
                .inActive{
                    background: #858d94;
                 }
            }

        }

        .profile_name {
            text-align: center;
            font-size: 20px;
            color: #ef5f6f;
            font-weight: 500;
            text-transform: capitalize;
        }

    }

    .profile_content {
        @include profile_box;
        padding: 30px;
        margin-top: 10px;

        .profile_form {
            .profile_head {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                border-bottom: 1px solid #dadada;
                padding-bottom: 12px;

                .title {
                    @include profile_title;
                }
            }

            .profile_change_btns {
                text-align: right;

                .cancel_btn {
                    background-color: transparent;
                    border: none;
                    border: 1px solid #EE5D6D;
                    border-radius: 25px;
                    padding: 0.4em 1.5em;
                    font-size: 17px;
                    font-weight: 500;
                    margin-right: 10px;
                }

                .primary_btn {
                    text-align: center;
                    width: auto;
                    padding: 0.5em 1.8em;
                }
            }

            .profile_inputs {
                padding-top: 10px;

                .inputs {
                    padding: 8px 0px;

                    .input_pwd_div{
                        position: relative;
                        .pwd_icon{
                            position: absolute;
                            top: 50%;
                            right: 25px;
                            transform: translate(10px, -50%);
                            cursor: pointer;
                        }
                    }

                    label {
                        margin-bottom: 0.5rem;
                        font-weight: 500;
                        font-size: 15px;
                        color: #525f7f;
                    }

                    input {
                        &:focus {
                            box-shadow: none;
                            border: 1px solid #ced4da;
                        }
                    }
                }
            }
        }
    }
}

.auth_2fa{
    display: flex;
    justify-content: center;
    /* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 25px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #EE5D6D;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #EE5D6D;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
}