.invoice_details_section {
    padding: 25px 25px;

    .invoice_detail_content {
        box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
        background: #fff;
        border-radius: 16px;
        margin-bottom: 10px;
        padding: 1rem 1rem;

        .invoice_title {
            padding: 1rem 1rem 2rem 1rem;
            border-bottom: 1px solid #D1CCCC;

            .back_btn {
                margin-bottom: 5px;

                a {
                    color: #3f2b2d;
                    margin-left: 10px;
                    font-size: 1.4em;
                }
            }

            .invoice_head {
                .head {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                }

                .status_dropdown {
                    select {
                        border: 1px solid gray;
                        border-radius: 3px;
                        padding: 3px 10px;
                    }
                }

                .invoice_head_content {
                    margin-left: 1em;
                    position: relative;

                    .copy_text {
                        background-color: #000;
                        color: #fff;
                        width: fit-content;
                        border-radius: 5px;
                        padding: 5px;
                        margin-left: auto;
                        font-size: 14px;
                        position: absolute;
                        right: 0;
                        top: -50%;
                    }

                    // button {
                    //     background: transparent;
                    //     border: none;
                    // }

                    h6 {
                        font-weight: 700;
                    }

                    p {
                        color: #646464;
                        margin: 0%;
                    }
                }
            }

            h6 {
                font-size: 25px;
                margin-bottom: 0rem;
            }
        }


        .invoice_details_information {
            margin: 2em 0;
            border-bottom: 1px solid #D1CCCC;

            .middle {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .details_table_box {
                margin: 2em;
                padding: 1em;
                background-color: #f7f7f7;
                border: 1px solid #ebebeb;
                border-radius: 10px;

                tr {

                    td,
                    th {
                        border: none;
                        font-size: .9em;
                        color: #333;
                    }

                    .property_value {
                        text-align: end;
                        font-weight: 700;
                        font-size: 1em;
                    }
                }
            }

            .invoice_from_details {
                h6 {
                    font-size: 1.3em;
                    padding-left: 1.4em;
                }

                ul {
                    list-style-type: none;

                    li {
                        color: #646464;
                        font-size: 1em;
                    }
                }
            }
        }


        .invoice_data {
            margin-top: 25px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;

            h5 {
                color: #525f7f;
                padding-top: 20px;
            }

            h4 {
                color: #525f7f;
            }

            p {
                color: gray;
                font-size: 18px;
                font-weight: 500;
            }

            .invoice_left {

                .invoice_image {
                    width: 350px;

                    img {
                        width: 100%;
                    }
                }
            }

            .invoice_right {

                .invoice_amount {
                    height: 120px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;

                    p {
                        font-size: 21px;
                        color: #5b5a5a;
                        font-weight: 600;
                    }
                }
            }
        }

        .invoice_bottom_content {
            padding: 1rem;
        }

        .accept_reject_btn {
            display: flex;
            justify-content: flex-end;
        }
    }
}

@media screen and (max-width:767px) {
    .invoice_details_section .invoice_detail_content .invoice_data .invoice_left .invoice_image {
        width: 215px;
    }
}

@media screen and (max-width:525px) {
    .invoice_details_section .invoice_detail_content .invoice_data {
        justify-content: flex-start;
    }
}