.funding_section {
    padding: 25px 25px;

    .funding_content {
        box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
        background: #fff;
        border-radius: 5px;
        margin-bottom: 10px;
        padding: 2rem 2rem;

        .funding_title {
            h3 {
                font-weight: 700;
            }
        }

        .funds_payment {
            padding: 30px 20px;

            .availabel_fund_title {
                color: #525f7f;
                font-size: 20px;
            }

            .amount {
                color: #c21212c7;
                line-height: 0.3;
            }
        }

        .transaction_option {
            .transaction_option_title {
                display: flex;
                gap: 30px;

                .radio_input {
                    margin-top: 5px;
                }
            }

            .transaction_input {
                input {
                    width: 85%;
                    border-bottom: 2px solid gray;
                    border-top: 0px;
                    border-left: 0px;
                    border-right: 0px;
                    border-radius: 0px;

                    &:focus {
                        box-shadow: none;

                    }
                }
            }
        }

        .payment_options {
            display: flex;
            align-items: center;
            gap: 20px;
            padding: 1rem 0rem;

            img {
                width: 120px;
            }
        }
    }
}