.inside404_section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 75px);

    .box {
        text-align: center;

        a {
            background-color: #EE5D6D;
            padding: 0.5em 2.5em;
            color: #fff;
            border-radius: 25px;
            box-shadow: inset 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
            transition: all .2s;

            &:hover {
                box-shadow: inset 0px 0px 5px 1px rgb(0 0 0 / 60%);
            }
        }
    }
}

