@import "./common/header.scss";
@import "./heroSection.scss";
@import "./userFeature.scss";
@import "./counter.scss";
@import "./dashboardFeature.scss";
@import "./aboutTrade.scss";
@import "./contact.scss";
@import "./common/footer.scss";


.landing_sections{
    background-color: #050710;
}