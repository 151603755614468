.p2p_exchange_section {
    padding: 100px 0px;

    .p2p_exchange_content {
        background: #FFFFFF;
        box-shadow: 0px 1px 2px rgba(56, 65, 74, 0.15);
        border-radius: 16px;
        padding: 2.5em;

        .title {
            padding: 10px 0px;

            h3 {
                font-size: 25px;
                font-weight: 700;
                text-align: center;
            }
        }

        .p2p_exchange_form {
            .swap_div {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                width: fit-content;
                margin: auto;

                .swap_div_icon {
                    background: #fbe8ea;
                    width: 35px;
                    height: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    border: 1px solid #fbd2d2;

                    svg {
                        transform: rotate(90deg);
                        font-size: 20px;
                    }

                    &:hover {
                        background: #F2F4F8;
                        border: 1px solid #e3e3e3;
                    }
                }
            }

            .input_feild {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                background: #F2F4F8;
                border: 1px solid #EBEBEB;
                border-radius: 50px;
                margin: 5px 20px;
                // padding: 10px; 

                select {
                    font-size: 17px;
                    font-weight: 600;
                    border: none;
                    background: transparent;

                    &:focus {
                        outline: none;
                    }
                }

                label {
                    font-size: 17px;
                    font-weight: 600;
                    // width: 15%;
                    // text-align: center;
                    // position: relative;

                    //  &::after{
                    //     content: "";
                    //     position: absolute;
                    //     background-color: #D0D0D0;
                    //     height: 57px;
                    //     width: 1px;
                    //     bottom: -16px;
                    //     right: -6px;
                    //  }
                }

                input {
                    background-color: transparent;
                    border: none;
                    text-align: right;
                    width: 82%;
                    border-left: 1px solid #D0D0D0;
                    border-radius: 0px;
                    padding: 15px;

                    &:focus {
                        box-shadow: none;
                        border: none;
                        border-left: 1px solid #D0D0D0;
                    }
                }
            }

            .available_pmbc {
                padding-left: 25px;
                color: #535353;
                font-size: 16px;
                font-weight: 400;

                span {
                    color: #EE5D6D;
                }
            }

            .exchange_btns {
                margin: 20px;
                display: flex;
                justify-content: center;

                .primary_btn {
                    width: auto;
                }

                .primary_btn:disabled {
                    background-color: #969696;
                }
            }

            .note {
                color: #969696;
                font-size: 15px;
                font-weight: 400;
                text-align: center;
            }
        }
    }

}

@media screen and (max-width:500px) {
    .p2p_exchange_section .p2p_exchange_content {
        padding: 2em 0.5em;
    }

    .p2p_exchange_section .p2p_exchange_content .p2p_exchange_form .input_feild label {
        // width: 17%;
        text-align: center;
    }

    .p2p_exchange_section .p2p_exchange_content .p2p_exchange_form .input_feild input {
        width: 78%;
    }
}


.p2p_note_modal {
    .modal-dialog {
        width: 409px !important;
    }

    .modal-header {
        border-bottom: none;
    }

    .modal-body {
        padding-top: 0rem;

        .p2p_note {
            padding: 0px 30px;

            img {
                width: 20%;
                display: flex;
                margin: auto;
            }

            h2 {
                text-align: center;
                font-size: 3em;
                margin-top: 10px;
            }

            p {
                font-size: 18px;
                text-align: justify;

                span {
                    font-weight: 500;
                    color: #ee5d6d;
                }
            }
            img{
                width: 66px;
                display: block;
                margin: auto;
            }
        }
    }
}