.mainkyc_detail_section{
    padding: 25px 25px;

    .business_detail_tabs {
        // box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
        background: #EE5D6D;
        border-radius: 16px;
        margin-bottom: 10px;
        padding: 1rem 1rem;

    }

    .nav-tabs .nav-item {
        padding: 0px 20px;
    }

    .nav-tabs .nav-link {
        font-weight: 500;
        color: rgb(255 255 255);
        padding: 8px 0px !important;
        border-bottom: 2px solid transparent;
        transition: auto;
        border-top: none;
        border-left: none;
        border-right: none;

        &:hover {
            border: none;
            padding: 8px 0px;
        }
    }

    .nav-tabs .nav-link.active {
        color: #fff;
        font-weight: 500;
        border-bottom: 2px solid #fff;
        border-top: none;
        border-left: none;
        border-right: none;
        padding: 8px 0px;
        background-color: #EE5D6D;
    }
}


@media screen and (max-width:550px){
    .kyc_view_section .kyc_detail_form .kyc_detail_inputs .document_type .select_document_btn{
        width: 77%;
    }
    .kyc_view_section .kyc_detail_form .kyc_detail_inputs .document_type{
        gap:0px
    }
}
@media (min-width:551px) and (max-width:991px){
    .kyc_view_section .kyc_detail_form .kyc_detail_inputs .document_type .select_document_btn{
        width: 33%;
    }
}