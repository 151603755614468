@mixin ul-class {
    margin-left: -50px;
    margin-bottom: 0rem;
    padding-top: 5px;
}

@mixin li_class {
    padding: 8px 0px 8px 15px;
    transition: all 0.3s ease-in-out;
    border-left: 4px solid transparent;

}

@mixin title {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
}

@mixin sidenav_active_links {
    display: flex;
    justify-content: flex-end;
    padding-right: 17px;

    &:hover {
        border-radius: 30px 0px 0px 30px;
        width: auto;
        margin-left: 190px;
    }

    span {
        display: none;
    }
}


.sideNav_active {
    .sidenav-menu {
        transform: matrix(1, 0, 0, 1, -190, 0);

        .sidenav_top_logo {
            padding: 0px 15px 20px 20px;
            .toggle_btn {
                &:hover>svg {
                    transform: rotate(0deg);
                }

                svg {
                    transform: rotate(180deg);
                }
            }
            .img{
                .toggle_logo{
                    opacity: 1;
                }
            }
        }
       
        .sidenav_menu_content {
            .menu_wrap {
                .menu_title {
                    text-align: right;
                    padding-right: 13px;
                }

                .menu_items li {
                    .nav_links {
                        @include sidenav_active_links;
                    }
                }

                .menu_items li.active {
                    border-radius: 30px 0px 0px 30px;
                    width: auto;
                    margin-left: 190px;

                    .nav_links {
                        &:hover {
                            padding: 8px 17px;
                            margin-left: 0px;
                        }
                    }
                }

            }

            .account_wrap {
                .account_title {
                    text-align: right;
                    padding-right: 5px;
                }

                .account_items li {
                    .nav_links {
                        @include sidenav_active_links;
                    }
                }

                .account_items li.active {
                    border-radius: 30px 0px 0px 30px;
                    width: auto;
                    margin-left: 190px;

                    .nav_links {
                        &:hover {
                            padding: 8px 17px;
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
    }

    .main_content {
        margin-left: 60px;
    }
}

.sidenav-menu {
    width: 250px;
    z-index: 1002;
    background: #323232;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    -webkit-box-shadow: 0 2px 4px rgba(15, 34, 58, .12);
    box-shadow: 0 2px 4px rgba(15, 34, 58, .12);
    padding: 0 0 calc(70px + 25px) 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .1s ease-out;
    transform: matrix(1, 0, 0, 1, 0, 0);

    .sidenav_top_logo {
        padding: 0px 20px 20px 20px;
        position: relative;
        background: #1E1617;
        border-bottom: 1px solid #FF465A;

        .toggle_btn {
            background: transparent;
            border: none;

            &:hover>svg {
                transform: rotate(180deg);
            }

            svg {
                position: absolute;
                top: 28px;
                right: -20px;
                font-size: 2em;
                border-radius: 20px;
                background: #fff;
                color: #FF465A;
                padding: 1px;
                transition: all .3s;
            }
        }

        .img {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .toggle_logo {
                width: 47px;
                margin-right: -10px;
                opacity: 0;
            }
        }

        a {
            text-decoration: none;
            color: #5e72e4;
        }
    }

    .sidenav_menu_content {
        padding: 16px;
        padding-right: 0px;
        overflow-y: auto;
        height: 100%;

        .menu_wrap {
            padding: 10px 0px 10px 10px;

            .menu_title {
                @include title;
            }

            .menu_items {
                ul {
                    @include ul-class;
                }

                li.active {
                    transition: all 0.3s ease-in-out;
                    background: #fff;
                    border-right: 4px solid #EE5D6D;
                    border-radius: 28px 0px 0px 28px;

                    .nav_links {
                        &:hover {
                            border-right: 0px;
                            padding: 8px 0px 8px 15px;
                        }

                        .icon {
                            svg {
                                color: #323232;
                            }
                        }

                        span {
                            color: #323232;
                        }
                    }

                    &:hover {

                        .nav_links {
                            .icon {
                                svg {
                                    color: #323232;
                                }
                            }

                            span {
                                color: #323232;
                            }
                        }
                    }
                }

                li {
                    a {

                        @include li_class;

                        &:hover {
                            padding-left: 20px;
                            // padding: 8px 0px 8px 15px;
                            transition: all 0.3s ease-in-out;
                            border-radius: 28px 0px 0px 28px;
                            background: #b1bcfd1a;
                            border-right: 4px solid #EE5D6D;

                        }

                    }

                    &:hover {

                        .nav_links {
                            .icon {
                                svg {
                                    color: #fff;
                                }
                            }

                            span {
                                color: #fff;
                            }
                        }
                    }

                    .nav_links {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        .icon {
                            svg {
                                font-size: 22px;
                                color: #fff;
                            }
                        }

                        span {
                            font-size: 16px;
                            color: #fff;
                            padding-top: 5px;
                            width: 100%;
                        }
                    }

                }
            }
        }

        .account_wrap {
            padding: 10px 0px 10px 10px;

            .account_title {
                @include title;
            }

            .account_items {
                ul {
                    @include ul-class;
                }

                li.active {
                    transition: all 0.3s ease-in-out;
                    background: #fff;
                    border-right: 4px solid #EE5D6D;
                    border-radius: 28px 0px 0px 28px;

                    .nav_links {
                        &:hover {
                            border-right: 0px;
                            padding: 8px 0px 8px 15px;
                        }

                        .icon {
                            svg {
                                color: #323232;
                            }
                        }

                        span {
                            color: #323232;
                        }
                    }
                }

                li {

                    a {

                        @include li_class;

                        &:hover {
                            padding-left: 20px;
                            // padding: 8px 0px 8px 15px;
                            transition: all 0.3s ease-in-out;
                            border-radius: 28px 0px 0px 28px;
                            background: #b1bcfd1a;
                            border-right: 4px solid #EE5D6D;

                            .nav_links {
                                .icon {
                                    svg {
                                        color: #6173e0;
                                    }
                                }

                                span {
                                    color: #6173e0;
                                }
                            }
                        }
                    }

                    .nav_links {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        .icon {
                            svg {
                                font-size: 22px;
                                color: #fff;
                            }
                        }

                        span {
                            font-size: 16px;
                            color: #fff;
                            padding-top: 5px;
                            width: 100%;
                        }
                    }

                }
            }
        }
    }
}

.sidenav_menu_content {
    --scrollbarBG: rgb(194, 193, 193);
    --thumbBG: #EE5D6D;
  }
  .sidenav_menu_content::-webkit-scrollbar {
    width: 10px;
  }
  .sidenav_menu_content {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  .sidenav_menu_content::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  .sidenav_menu_content::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 5px;
    border: 3px solid var(--scrollbarBG);
  }



@media screen and (max-width:1025px) {
    .dash_layout {
        &::before {
            content: '';
            position: fixed;
            top: 0%;
            left: 0%;
            width: 100vw;
            height: 100vh;
            background: rgba($color: #000000, $alpha: 0.5);
            z-index: 1;
        }
    }

    .dash_layout.sideNav_active {
        &::before {
            content: unset;
        }
    }
}


@media screen and (max-width:1023px) {
    .sideNav_active .sidenav-menu {
        transform: matrix(1, 0, 0, 1, -250, 0);
    }

    .sideNav_active .main_content {
        margin-left: 0px;
    }
}


.cms_content{
    .accordion{
        --bs-accordion-btn-icon:url("../../images/down_arrow.png");
        --bs-accordion-bg: #323232;
        --bs-accordion-border-radius:0px;
        --bs-accordion-btn-focus-border-color: #323232;
        --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgb(50 50 50);
        .accordion-item{
            border-color:#323232;
            .accordion-header{
                padding: 0px 10px 0px 0px;
            }
            .accordion-button{
                background: #323232;
                border-radius: 0px;
                color: #fff;
                font-weight: 500;
                padding: 5px 10px;
            }
            // .accordion-button::after {
            //     filter: invert(1);
            // }
            .accordion-button:not(.collapsed)::after {
             background-image:url("../../images/down_arrow.png");   
            }
            .accordion-button:not(.collapsed){
                box-shadow: none;
            }

            .accordion-body{
                padding: 0px 0px 0px 10px;
    background: #323232;
    border: none;
            }
        }
    }
}