.lending_section {
    padding: 25px 25px;
    height: 90vh;
    display: flex;
    flex-direction: row;
    align-items: center;

    .crypo_loans {
        background: #EE5D6D;
        border-radius: 20px;
        width: fit-content;
        font-size: 11px;
        padding: 2px 10px;
        color: #fff;
        font-weight: 600;
        letter-spacing: 1.1px;
        margin-bottom: 12px;

    }

    .lending_content {
        box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
        background: #fff;
        border-radius: 16px;
        margin-bottom: 10px;
        padding: 1rem 1rem;
        .border_row{
            margin: 0.2rem;
            border: 1px solid rgba(50, 58, 70, 0.1);
            border-radius: 16px;
            .right_border_col{
                border-right: 1px solid rgba(50, 58, 70, 0.1);
            }
             .left_content{
                padding: 1rem 0rem;
                 .title{
                    font-weight: 700;
                    font-size: 52px;
                    span{
                        color: #EE5D6D;
                    }
                 }
                 .desc{
                    color: #34373A;
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 1.4;
                    padding: 10px 0px;
                 }
                .features{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                     h2{
                        font-weight: 400;
                        font-size: 43px;
                        margin-bottom: 0rem;
                        width: 25%;
                     }
                     p{
                        font-weight: 400;
                        font-size: 13px;
                        margin-bottom: 0rem;
                     }
                     .per_30{
                        width: 30%;
                     }
                     .per_20{
                        width: 20%;
                     }
                 }
             }
             .loan_col{
                position: relative;
             }
        }

    }
}

@media screen and (max-width:991px){
    .lending_section {
        height: auto;
    }
    .lending_section .lending_content .border_row .left_content .title{
        font-size: 48px;
    }   
}
@media (min-width:992px) and (max-width:1023px){
    .lending_section .lending_content .border_row .left_content .title{
        font-size: 44px;
    }   
}


