@mixin invoice_status {
    color: #fff;
    padding: 3px 7px;
    border-radius: 3px;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
    width: 90px;
}

.invoice_overview_section {
    padding: 25px 25px;

    .invoice_overview_content {
        box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
        background: #fff;
        border-radius: 16px;

        .invoice_title {
            border-bottom: 1px solid #e9ebec;
            padding: 1rem 1rem;

            h6 {
                font-size: 17px;
                margin-bottom: 0rem;
            }
        }

        .table_content {
            padding: 1rem 1rem;

            .table_search {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: flex-end;

                .search_input {
                    padding: 0.375rem 0.75rem;
                    color: #212529;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                    border-radius: 0.375rem;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    height: 37px;

                    input {
                        border: none;
                        padding: 0rem 0rem;
                        width: 360px;

                        &:focus {
                            box-shadow: none;
                            border: none;
                        }
                    }
                }

                .filter_input {
                    display: flex;
                    align-items: center;

                    label {
                        width: 100px;
                        font-weight: 500;
                        color: #525f7f;
                    }

                    input {
                        width: 420px;

                        &:focus {
                            box-shadow: none;
                            border: 1px solid #ced4da;
                        }
                    }
                    .form-select:focus {
                        border-color: #ced4da;
                    }
                }
            }

            .invoice_table {
                padding: 1rem 0rem;

                .MuiTableContainer-root {
                    border: 1px solid #80808033;

                    .MuiTable-root {
                        thead {
                            background: #f1f1f1;

                            tr {
                                .MuiTableCell-head {
                                    font-weight: 600 !important;
                                }
                            }
                        }

                        p {
                            margin-bottom: 0rem !important;
                        }

                        .MuiTableCell-root {
                            border-right: 1px solid #80808033;
                            padding: 10px;

                            &:nth-last-child(-n+1) {
                                border-right: 0px;
                            }

                            .new_invoice {
                                background: rgb(10, 179, 156);
                                @include invoice_status;
                            }

                            .closed_invoice {
                                background: rgb(240, 101, 72);
                                @include invoice_status;
                            }

                            .inProgress_invoice {
                                background: #d2c210;
                                @include invoice_status;
                            }
                        }
                    }
                }

                .select_class {
                    border: 1px solid gray;
                    border-radius: 3px;
                    padding: 2px 1px;
                    font-size: 0.875rem;
                }

                .status_btn{
                    width: 90px;
                    text-align: center;
                }
            }
        }
    }
    .lending_transaction{
        position: relative;
        cursor: pointer;
        .copy_text{
            position: absolute;
            top: -12px;
            left: 10%;
            background: rgb(0 0 0 / 92%);
            color: #fff;
            z-index: 1;
            padding: 0px 6px;
            border-radius: 4px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            &::after{
                content: "";
                top: 15px;
                width: 10px;
                height: 10px;
                background-color: rgb(0 0 0 / 84%);
                position: absolute;
                display: inline-table;
                right: 50%;
                transform: rotate(45deg);
                z-index: -2;
            }
        }
    }
}

@media screen and (max-width:767px) {
    .invoice_overview_section .invoice_overview_content .table_content .table_search .search_input input {
        width: 100%;
    }

    .invoice_overview_section .invoice_overview_content .table_content .table_search .filter_input {
        flex-wrap: wrap;
    }

    .invoice_overview_section .invoice_overview_content .table_content .table_search .filter_input input {
        width: 100%;
    }
}

@media (min-width:768px) and (max-width:1013px) {
    .invoice_overview_section .invoice_overview_content .table_content .table_search .filter_input {
        margin-top: 12px;
    }
}