.admin_marketplace {

    .heading {
        box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
        background: #fff;
        border-radius: 16px;
        padding: 12px 25px;
        margin: 15px 0px;

        h2 {
            margin: 0%;
        }
    }


    .nft_card {
        box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
        background: #fff;
        border-radius: 16px;
        padding: 25px;
        margin: 15px 0px;

        .content {
            margin-top: 1em;
        }
    }

    .allNFT {
        background: #EE5D6D;
        border-radius: 16px;
        margin: 1em 0;
        padding: 1rem 1rem;

        .nav-item {
            margin: 0 1em;

            .nav-link.active {
                color: #fff;
                font-weight: 500;
                border-bottom: 2px solid #fff;
                border-top: none;
                border-left: none;
                border-right: none;
                padding: 8px 0px;
                background-color: #EE5D6D;
            }

            .nav-link {
                font-weight: 500;
                color: rgb(255, 255, 255);
                padding: 8px 0px !important;
                border-bottom: 2px solid transparent;
                transition: auto;
                border-top: none;
                border-left: none;
                border-right: none;
            }
        }
    }

}