.loan_section {
    .loan_col_form {
        position: absolute;
        top: -110px;
    }

    p {
        margin-bottom: 0rem;
    }

    .loan_form {
        background: #FFFFFF;
        box-shadow: 0px -5px 5px rgba(0, 26, 67, 0.07);
        border-radius: 30px;
        // height: 470px;

        .loan_title {
            border-bottom: 0.3px solid #bababa;
            text-align: center;
            font-size: 18px;
            padding: 13px;

        }

        .loan_form_data {
            padding: 0.75em 1.1em;

            .sub_title {
                color: #1D1D1D;
                font-size: 23px;
            }

            .input_field_data {
                label {
                    font-size: 14px;
                    color: #1D1D1D;
                }

                .input_field {
                    background: #F4F4F4;
                    border-radius: 16px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    margin-top: 5px;

                    input {
                        border: none;
                        background-color: transparent;
                        border-right: 1px solid #D0D0D0;
                        border-radius: 0px;
                        padding: 1em;
                        width: 80%;

                        &:focus {
                            box-shadow: none;
                            border: none;
                            border-right: 1px solid #D0D0D0;
                        }
                    }

                    .coin_drop {
                        display: flex;
                        gap: 12px;
                        align-items: center;
                        justify-content: center;
                        color: #898989;
                        font-weight: 400;

                        .img_div {
                            display: flex;
                            gap: 5px;
                            align-items: center;

                            img {
                                width: 18px;
                            }

                            select {
                                font-size: 17px;
                                border: none;
                                background: transparent;
                                appearance: none;
                                margin-right: 1em;

                                &:focus {
                                    outline: none;
                                }
                            }
                        }

                    }
                }

                .get_fund_div {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;

                    .percentage_value_div {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 35px;
                        justify-content: space-between;


                        .ltv {
                            color: #898989;
                            font-size: 15px;
                            font-weight: 400;
                        }

                        .percentages {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            gap: 20px;

                            p {
                                font-weight: 400;
                                font-size: 14px;
                                color: #898989;
                                border: 1px solid #E5E6EB;
                                border-radius: 6px;
                                padding: 1px 8px;
                                text-align: center;
                                cursor: pointer;
                            }

                            .active {
                                border: 1px solid #FF9900;
                                color: #FF9900;
                            }
                        }
                    }
                }
            }

            .loan_table {
                margin-top: 2rem;

                table {
                    margin-bottom: 0rem;

                    tbody {
                        tr {
                            td {
                                border-bottom: 0px;
                                color: #2B2B39;
                                font-size: 15px;
                            }

                            .apr_div {
                                display: flex;
                                justify-content: flex-end;
                                gap: 15px;

                                .apr_percentage {
                                    border: 1px solid #FF9900;
                                    border-radius: 6px;
                                    color: #FF9900;
                                    padding: 1px 11px;
                                }

                            }
                        }
                    }
                }
            }

            .loan_detail {
                padding: 1.5rem 0px 1rem 0px;
                display: flex;
                flex-wrap: wrap;

                .loan_content {
                    display: flex;
                    align-items: center;

                    .title {
                        font-size: 14px;
                        line-height: 15px;
                        color: #1d1d1d;
                        opacity: .5;
                        margin-right: 5px;
                    }

                    .value {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 28px;
                        color: #1d1d1d;
                        padding-right: 12px;
                        margin-right: 12px;
                        border-right: 1px solid #e5e6eb;

                        span {
                            font-weight: 400;
                        }
                    }

                    &:last-child {
                        .value {
                            border-right: none;
                        }
                    }
                }
            }

            .loan_interest {
                border-top: 1px solid #e5e6eb;
                border-bottom: 1px solid #e5e6eb;
                padding: 10px 0px;

                .interest_content {
                    display: flex;
                    justify-content: space-between;
                    padding: 8px 0px;

                    .title {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 20px;
                        color: #2b2b39;
                    }

                    .value {
                        color: #1d1d1d;
                        font-size: 16px;
                    }
                }
            }

            .input_field_data_loan {
                padding-top: 1rem;

                .input_field_pbmc {
                    margin-top: 5px;

                    input {
                        padding: 0.5em;

                        &:focus {
                            box-shadow: none;
                            border: 1px solid #ced4da;
                        }

                        &::placeholder {
                            color: rgb(227, 225, 225);
                        }
                    }
                }

                .input_field_email {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 15px;

                    .email_input {
                        margin-top: 5px;
                        padding: 0.5em;
                        width: 75%;
                        position: relative;

                        &:focus {
                            box-shadow: none;
                            border: 1px solid #ced4da;
                        }

                        &::placeholder {
                            color: rgb(227, 225, 225);
                        }
                    }

                    .check_mark {
                        position: absolute;
                        left: 65%;
                        bottom: 18%;
                        font-size: 2em;
                    }

                    .verify_otp_div {
                        width: 15%;

                        .verify_btn {
                            margin-top: 5px;
                            border: 1px solid #1168f1;
                            background-color: transparent;
                            padding: 0.36em 2em;
                            font-weight: 500;
                            font-size: 17px;
                            color: #1168f1;
                            border-radius: 0.375rem;

                            &:hover {
                                color: #fff;
                                background-color: #1168f1;
                            }
                        }

                        input {
                            margin-top: 5px;
                            padding: 0.5em;
                            width: 149%;

                            &:focus {
                                box-shadow: none;
                                border: 1px solid #ced4da;
                            }

                            &::placeholder {
                                color: rgb(227, 225, 225);
                            }
                        }
                    }
                }

            }

            .verify_text {
                font-size: 13px;
                color: #1168f1;
            }
        }

        .get_loan_btn {
            .primary_btn {
                width: 100%;
                padding: 0.7em 2.5em;
                border-radius: 0px 0px 30px 30px;
            }
        }

    }
}

@media screen and (max-width:991px) {
    .loan_section {
        padding: 1rem 0rem;
    }

    .loan_section .loan_col_form {
        position: initial;
    }
}

@media screen and (max-width:550px) {
    .lending_section .lending_content .border_row .left_content .features h2 {
        width: 50%;
    }

    .lending_section .lending_content .border_row .left_content .features .per_30 {
        width: 50%;
    }

    .lending_section .lending_content .border_row .left_content .features .per_20 {
        width: 50%;
    }

}


@media (min-width:1024px) and (max-width:1399px) {
    .lending_section {
        height: 90vh;
    }

    .loan_section .loan_col_form {
        top: -70px;
    }
}

@media (min-width: 1287px) and (max-width: 1399px) {
    .loan_section .loan_col_form {
        top: -45px;
    }
}

@media (min-width: 1400px) and (max-width: 1651px) {
    .loan_section .loan_col_form {
        top: -50px;
    }
}