.settings_section{
    padding: 25px 25px;
        .settings_content{
            box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
            background: #fff;
            border-radius: 5px;
            margin-bottom: 10px;
            padding: 2rem 2rem;
             .settings_header{
                display: flex;
                justify-content: space-between;
                border-bottom: 2px solid rgba(128, 128, 128, 0.6);

               .search_input {
                        padding: 0.375rem 0.75rem;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.375rem;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        height: 37px;
    
                        input {
                            border: none;
                            padding: 0rem 0rem;
                            width: 360px;
    
                            &:focus {
                                box-shadow: none;
                                border: none;
                            }
                        }
                    }
              }
        }
        .settings_bottom{
            padding-top: 40px;
             .settings_msg_right{
                display: flex;
                justify-content: space-between;
                .form-switch .form-check-input {
                    width: 4em;
                    height: 2rem;
             }
            }
        }
}